import axios from '@axios'
import { endpoint } from '@/@core/endpoint'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmails(ctx, payload) {
      const ee = new Promise((resolve, reject) => {
        // console.log('/apps/email/emails', payload)
        axios
          .get('/apps/email/emails', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
      // console.log('responce', ee)
      return ee
    },
    fetchExcerptsSearch(ctx, payload) {
      return new Promise((resolve, reject) => {
        // console.log('/excerpt/search', payload)
        axios
          .get(`${endpoint()}/excerpt/search`, { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/email/update-emails', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchKadFiles(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${endpoint()}/excerpt/fetch-kad-files`, { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmailLabels(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/email/update-emails-label', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paginateEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/email/paginate-email', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
