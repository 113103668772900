<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ emailViewData.kad }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">

        <feather-icon
          v-if="selectAllFilesSingle"
          icon="ShoppingCartIcon"
          class="ml-75 cursor-pointer"
          size="17"
          @click="AddSelectedToCart()"
        />
        <feather-icon
          v-if="selectAllFilesSingle"
          icon="XIcon"
          class="ml-75 cursor-pointer"
          size="17"
          @click="RemoveSelectedFromCart()"
        />

        <b-dropdown
          v-if="selectAllFilesSingle"
          variant="link"
          no-caret
          toggle-class="p-0"
          class="ml-75"
          right
        >
          <template #button-content>
            <feather-icon
              icon="DownloadIcon"
              size="17"
              class="align-middle text-body"
            />
            Скачать
          </template>
          <b-dropdown-item v-if="false" @click="$emit('update-email-label', 'personal')">
            <span class="mr-50 bullet bullet-success bullet-sm" />
            <span>HTML</span>
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="XMLMultyDownload(selectFilesFolder)">
            <span class="mr-50 bullet bullet-primary bullet-sm" />
            <span>XML</span>
          </b-dropdown-item>
        </b-dropdown>

        <!-- Show Previous Mail -->
        <feather-icon
          :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
          size="17"
          class="ml-75 cursor-pointer"
          :class="{'text-muted pointer-events-none': !opendedEmailMeta.hasPreviousEmail}"
          @click="$emit('change-opened-email', 'previous')"
        />

        <!-- Show Next Mail -->
        <feather-icon
          :icon="$store.state.appConfig.isRTL ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
          size="17"
          class="ml-75 cursor-pointer"
          :class="{'text-muted pointer-events-none': !opendedEmailMeta.hasNextEmail}"
          @click="$emit('change-opened-email', 'next')"
        />
      </div>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >

      <!-- Label Row -->
      <b-row>
        <b-col cols="12">
          <div class="email-label">
            <b-badge
              v-for="(label) in emailViewData.labels"
              :key="label"
              pill
              class="text-capitalize mr-50"
              :variant="`light-${resolveLabelColor(label)}`"
            >
              {{ label }}
            </b-badge>
          </div>
        </b-col>
      </b-row>

      <!-- Action: Show Earlier Message -->
      <b-row
        v-if="!showWholeThread && emailViewData.replies && emailViewData.replies.length"
        class="mb-1"
      >
        <b-col cols="12">
          <b-link
            class="font-weight-bold"
            @click="showWholeThread = true"
          >
            View {{ emailViewData.replies.length }} Earlier Message{{ emailViewData.replies.length > 1 ? 's' : '' }}
          </b-link>
        </b-col>
      </b-row>

      <!-- Email Actions: Reply or Forward -->
      <b-row v-if="emailViewData && emailViewData.files && emailViewData.files.length > 0">
        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-between p-1">
              <b-form-checkbox
                :checked="selectAllFilesCheckbox"
                :indeterminate="isSelectAllFilesCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Выбрать все
              </b-form-checkbox>
              Количество файлов: {{ emailViewData.files.length }}
            </div>
            <b-table
              ref="refExcerptListTable"
              :items="emailViewData.files"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="Нет ни одного файла"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
            >

              <template #head(excerptStatus)>
                <feather-icon
                  icon="TrendingUpIcon"
                  class="mx-auto"
                />
              </template>
              <!-- Column: Id -->
              <template #cell(change)="data">
                <b-form-checkbox
                  :checked="isSelectFiles(data.item.id)"
                  @change="toggleSelectedFile(data.item)"
                  @click.native.stop
                />
              </template>

              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <!-- Column: Excerpt Status -->
              <template #cell(status)="data">
                <b-tooltip
                  :target="`excerpt-row-${data.item.id}`"
                  placement="top"
                >
                  <p class="mb-0">
                    {{ data.item.status }}
                  </p>
                </b-tooltip>
              </template>

              <!-- Column: Client -->
              <template #cell(client)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="data.item.avatar"
                      :text="avatarText(data.item.client.name)"
                      :variant="`light-${resolveClientAvatarVariant(data.item.excerptStatus)}`"
                    />
                  </template>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.client.name }}
                  </span>
                  <small class="text-muted">{{ data.item.client.companyEmail }}</small>
                </b-media>
              </template>

              <!-- Column: Issued Date -->
              <template #cell(dateCreate)="data">
                <span :class="'text-nowrap text-' + GetColorByDate(new Date(data.value))">
                  {{ new Date(data.value).toLocaleDateString() + " " + new Date(data.value).toLocaleTimeString() }}
                </span>
              </template>

              <template #cell(isYou)="data">
                <span v-if="data.value" class="text-nowrap">
                  <b-link target="_blank" :href="'/apps/excerpt/preview/' + data.item.id">
                    Открыть
                  </b-link>
                  |
                  <b-link @click.prevent="XMLSingleDownload(data.item.id, data.item.fileTypeId)">
                    XML
                  </b-link>
                </span>
                <span v-else class="text-nowrap">
                  <span v-if="isCart(data.item.id)">
                    В корзине
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer"
                      size="17"
                      @click="RemoveFile(data.item.id)"
                    />
                  </span>
                  <span v-else>
                    {{ "150" }}
                    <feather-icon
                      icon="WindIcon"
                      class="mx-auto"
                      color="red"
                    />
                    {{ " | " }}
                    <feather-icon
                      icon="ShoppingCartIcon"
                      class="cursor-pointer"
                      size="17"
                      @click="ShopFile(data.item)"
                    />
                  </span>
                </span>
              </template>

              <!-- Column: Balance -->
              <template #cell(balance)="data">
                <template v-if="data.value === 0">
                  <b-badge
                    pill
                    variant="light-success"
                  >
                    Paid
                  </b-badge>
                </template>
                <template v-else>
                  {{ data.value }}
                </template>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    v-show="data.item.status == 'file-ok'"
                    :id="`excerpt-row-${data.item.id}-preview-icon`"
                    icon="EyeIcon"
                    size="16"
                    class="mx-1"
                    @click="$router.push({ name: 'apps-excerpt-preview', params: { id: data.item.id }})"
                  />
                  <b-tooltip
                    title="Просмотр файла"
                    :target="`excerpt-row-${data.item.id}-preview-icon`"
                  />
                </div>
              </template>
              <template #cell(fileType)="data">
                <span :style="{ color: data.item.fileTypeId === 9 || data.item.fileTypeId === 10 ? 'green' : (data.item.fileTypeId === 13 ? 'orange' : 'unset') }">
                  <span v-if="data.item.fileType === '109'">КПТ</span>
                  <span v-else>{{ data.item.fileType }}</span>
                  | {{ data.item.fileTypeId }}
                </span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BRow, BCol, BBadge, BCard, BLink, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  ref,
  watch,
  computed,
  onUnmounted,
} from '@vue/composition-api'
import excerptStoreModule from '../excerptStoreModule'
import useEmail from './useEmail'
import useDownloads from '../useDownloads'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,
    BTable,
    BFormCheckbox,
    VuePerfectScrollbar,
  },
  props: {
    emailViewData: {
      type: Object,
      required: true,
    },
    opendedEmailMeta: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const EXCERPT_APP_STORE_MODULE_NAME = 'app-excerpt'

    // Register module
    if (!store.hasModule(EXCERPT_APP_STORE_MODULE_NAME)) store.registerModule(EXCERPT_APP_STORE_MODULE_NAME, excerptStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EXCERPT_APP_STORE_MODULE_NAME)) store.unregisterModule(EXCERPT_APP_STORE_MODULE_NAME)
    })

    const { resolveLabelColor } = useEmail()
    const sortBy = ref('dateCreate')
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    const isSortDirDesc = ref(true)
    const selectedFiles = ref([])
    const selectAllFilesCheckbox = computed(() => store.getters['app-ecommerce/isSelectFilesIdds'](props.emailViewData.files.map(iii => iii.id)))
    const selectAllFilesSingle = computed(() => {
      if (props.emailViewData && props.emailViewData.files) {
        return store.getters['app-ecommerce/isSelectFilesIddsSingle'](props.emailViewData.files.map(iii => iii.id))
      }

      return false
    })
    const selectFilesFolder = computed(() => store.getters['app-ecommerce/selectFilesFolder'](props.emailViewData.files.map(iii => iii.id)))
    const isCart = computed(() => idFile => store.getters['app-ecommerce/isCard'](idFile))

    const selectAllCheckboxUpdate = val => {
      if (val) {
        for (let i = 0; i < props.emailViewData.files.length; i += 1) {
          store.commit('app-ecommerce/ADD_SELECT_FILE', props.emailViewData.files[i].id)
        }
      } else {
        for (let i = 0; i < props.emailViewData.files.length; i += 1) {
          store.commit('app-ecommerce/REMOVE_SELECT_FILE', props.emailViewData.files[i].id)
        }
      }
    }
    const GetColorByDate = date => {
      const difference = new Date().getTime() - date.getTime()
      const totalDays = Math.ceil(difference / (1000 * 3600 * 24))
      if (totalDays <= 7) return 'success'
      if (totalDays <= 30) return 'primary'
      if (totalDays <= 180) return 'warning'
      return 'danger'
    }
    const ShopFile = file => {
      // console.log(file, props.emailViewData)
      // eslint-disable-next-line no-param-reassign
      file.kad = props.emailViewData.kad
      store.commit('app-ecommerce/ADD_PRODUCT_TO_CART', file)
    }
    const AddSelectedToCart = () => {
      selectedFiles.value.forEach(item => {
        const file = props.emailViewData.files.filter(ii => ii.id === item)[0]
        if (file) ShopFile(file)
      })
    }

    const RemoveFile = idFile => {
      store.commit('app-ecommerce/REMOVE_PRODUCT_FROM_CART', idFile)
    }
    const RemoveSelectedFromCart = () => {
      selectedFiles.value.forEach(item => {
        const files = props.emailViewData.files.filter(ii => ii.id === item)
        if (files && files.length === 1) {
          const file = files[0]
          RemoveFile(file.id)
        }
      })
    }
    const isSelectAllFilesCheckboxIndeterminate = computed(() => Boolean(selectedFiles.value.length) && props.emailViewData.files.length !== selectedFiles.value.length)
    const showWholeThread = ref(false)
    const isSelectFiles = computed(() => idFile => store.getters['app-ecommerce/isSelectFiles'](idFile))
    const toggleSelectedFile = fileInfo => {
      console.log(store.getters['app-ecommerce/isSelectFiles'](fileInfo.id))
      if (!store.getters['app-ecommerce/isSelectFiles'](fileInfo.id)) {
        store.commit('app-ecommerce/ADD_SELECT_FILE', fileInfo.id)
      } else {
        store.commit('app-ecommerce/REMOVE_SELECT_FILE', fileInfo.id)
      }
    }

    watch(() => props.emailViewData.id, () => {
      showWholeThread.value = false
    })

    const tableColumns = [
      {
        key: 'change',
        label: '',
        sortable: false,
        class: 'w25 px-1',
      },
      {
        key: 'id',
        label: '#',
        sortable: true,
        class: 'px-1',
      },
      {
        key: 'isYou',
        label: '',
        sortable: false,
        class: 'px-1',
      },
      {
        key: 'fileType',
        label: 'Тип',
        sortable: false,
        group: true,
        class: 'px-1',
      },
      {
        key: 'dateCreate',
        label: 'Дата',
        sortable: true,
        class: 'px-1',
      },
    ]

    const selectFilesCount = computed(() => store.getters['app-ecommerce/selectFilesCnt'])
    const selectFiles = computed(() => store.getters['app-ecommerce/selectFiles'])

    const {
      XMLSingleDownload,
      XMLMultyDownload,
    } = useDownloads()

    return {
      sortBy,
      tableColumns,
      isSortDirDesc,
      // UI
      perfectScrollbarSettings,
      showWholeThread,

      // useEmail
      resolveLabelColor,

      selectedFiles,
      selectAllFilesCheckbox,
      selectAllCheckboxUpdate,
      isSelectAllFilesCheckboxIndeterminate,
      toggleSelectedFile,

      GetColorByDate,
      ShopFile,
      RemoveFile,
      isCart,
      AddSelectedToCart,
      RemoveSelectedFromCart,

      XMLSingleDownload,
      XMLMultyDownload,

      selectFilesCount,
      selectFiles,
      isSelectFiles,
      selectAllFilesSingle,
      selectFilesFolder,
    }
  },
}
</script>

<style>
  .w25{
    width: 25px !important;
  }
</style>
